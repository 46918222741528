import React, { Component } from "react";
import Layout from "../../layout";
import { graphql } from "gatsby";
import HTMLContent from "../../components/htmlcontent";
import ExpandableText from "../../components/expandabletext/ExpandableText";

class WorkPage extends Component {
  render() {
    const papers = this.props.data.research.frontmatter.content;
    return (
      <Layout>
        {<HTMLContent html={this.props.data.research.html} />}
        <table>
          <tbody>
            {papers.map(paper => {
              const authors = paper.authors.replace("Trinity Chung", "<b>Trinity Chung</b>")
              return <tr>
                <td><img src={"/img/research/"+paper.img} width="300"></img></td>
                <td>
                  <b>{paper.title}</b>
                  <p className="is-size-7"><ExpandableText text={authors} maxwords={30} /></p>
                  <p>
                    {paper.published}
                    {paper.award ? <span> — <span className="has-text-primary">{paper.award}</span></span> : ""}
                    &nbsp;/ <a href={paper.paper}>Paper</a>
                    {paper.site ? <span> / <a href={paper.site}>Site</a></span> : ""}
                  </p>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        {<HTMLContent html={this.props.data.text.html} />}
      </Layout>
    );
  }
}

export default WorkPage;

export const pageQuery = graphql`
  query WorkPageQuery {
    text: markdownRemark(frontmatter: { for: { eq: "work" } }) {
      html
    }
    research: markdownRemark(frontmatter: { for: { eq: "research" } }) {
      html
      frontmatter {
        content {
          title
          img
          authors
          published
          award
          paper
          site
        }
      }
    }
  }
`;
