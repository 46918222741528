import React, { Component } from 'react';
import HTMLContent from "../../components/htmlcontent";

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleText = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  render() {
    const { expanded } = this.state;
    const words = this.props.text.split(" ");
    const isTruncated = !expanded && words.length > this.props.maxwords;

    return (
      <div>
        <div>
          {isTruncated ? (
            <span>
              {words.slice(0, this.props.maxwords).join(" ")}...
              <span onClick={this.toggleText} className="a">
                &nbsp;(expand)
              </span>
            </span>
          ) : (
            <span>
              {<HTMLContent html={this.props.text} />}
              {expanded && (
                <span onClick={this.toggleText} className="a">
                  &nbsp;(collapse)
                </span>
              )}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ExpandableText;
